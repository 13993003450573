import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Seo from "../../../components/seo"
import Breadcrumb from "../../../components/Breadcrumb"
import ShareIcons from "../../../utils/ShareIcons"
import { ArticleMain } from "../../../styles/Layout"
import {
  ArticleContent,
  ArticleTitle,
  ArticleWrapper,
  PostMeta,
  StyledPostContent,
  SubTitle,
} from "../../../components/Article/ArticleElements"
import {
  ProsAndCons,
  Rating,
  Verdict,
  VerdictBox,
} from "../../../components/Review/ReviewElements"
import VideoPlayer from "../../../components/VideoPlayer"
import AuthorBox from "../../../components/Article/AuthorBox"
import Newsletter from "../../../components/Newsletter"
import RelatedReviews from "../../../components/Review/RelatedReviews"
import EndShareButtons from "../../../EndShareButtons"
import MiniSidebar from "../../../components/MiniSidebar"

const ReviewPage = ({
  data: { site, review, relatedReviews, miniReview, brand, bizExec },
}) => {
  const {
    title,
    slug,
    seoDesc,
    date,
    // updatedAt,
    summary,
    pros,
    cons,
    price,
    functionality,
    style,
    overallRating,
    verdict,
    category,
    subCategory,
    featuredImage,
    body,
    author,
  } = review
  const reviewData = miniReview
  const brandData = brand
  const execData = bizExec

  const image = getImage(featuredImage)
  const imageSrc = getSrc(featuredImage) // image source for Open Graph
  const authorImage = getImage(author.photo)

  const [showMore, setShowMore] = useState(false)

  // Options for Contentful RichText parsing
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },

    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (_node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (_node, children) => <h5>{children}</h5>,

      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={slug}>{title}</Link>,

      // Handling images
      [BLOCKS.EMBEDDED_ASSET]: node => {
        // const {
        //   // fluid: { src },
        //   description,
        // } = node.data.target
        const articleImage = getImage(node.data.target)

        return (
          <GatsbyImage
            as="figure"
            image={articleImage}
            alt={node.data.target.description}
            style={{
              width: "720",
            }}
          />
        )
      },

      // Handling responsive videos
      [INLINES.HYPERLINK]: node => {
        // console.log(node.data.title)
        if (
          (node.data.uri.indexOf(`youtube.com`) ||
            node.data.uri.indexOf(`youtu.be`) ||
            node.data.uri.indexOf(`vimeo.com`) ||
            node.data.uri.indexOf(`wistia.com`) ||
            node.data.uri.indexOf(`vidme.com`) ||
            node.data.uri.indexOf(`dailymotion.com`) ||
            node.data.uri.indexOf(`twitch.com`) ||
            node.data.uri.indexOf(`vidyard.com`) ||
            node.data.uri.indexOf(`kaltura.com`) ||
            node.data.uri.indexOf(`streamable.com`) ||
            node.data.uri.indexOf(`facebook.com`)) !== -1
        ) {
          return <VideoPlayer url={node.data.uri} />
        }

        if (node.data.uri.includes(`ceiimages.com`)) {
          return (
            <Link
              to={node.data.uri.replace(/(http|https):\/\/ceiimages.com/, ``)}
              aria-label={node.data.title}
            >
              {node.content[0].value}
            </Link>
          )
        } else {
          return (
            <a
              href={node.data.uri.replace(/http:/, `https:`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          )
        }
      },
    },
  }

  return (
    <>
      <Seo
        title={title}
        description={seoDesc}
        image={imageSrc}
        imageAlt={title}
        article={false}
      />
      <ArticleMain>
        <Breadcrumb
          className=""
          categoryTitle={category?.title}
          categoryLink={category?.slug}
          subCategoryTitle={subCategory?.title}
          subCategoryLink={`/${subCategory?.slug}/`}
        />

        <ArticleWrapper id="main-content">
          <ArticleTitle>
            <h1>{title}</h1>
          </ArticleTitle>

          <PostMeta>
            <div className="authorInfo">
              <GatsbyImage
                className="authorImage"
                as="figure"
                image={authorImage}
                alt={author?.name}
              />
              <p>
                <time dateTime={new Date(date).toISOString()}>{date}</time>
                <span>{author?.name}</span>
              </p>
            </div>

            <ShareIcons
              url={`${site.siteMetadata.siteUrl}/${category.slug}/${subCategory.slug}/${slug}/`}
            />
          </PostMeta>

          {summary && <SubTitle>{summary}</SubTitle>}

          <MiniSidebar
            reviewData={reviewData}
            brandData={brandData}
            execData={execData}
          />

          <GatsbyImage
            as="figure"
            image={image}
            alt={title}
            style={{
              height: "60vh",
              gridColumn: "full-start/full-end",
              // gridRow: "4/5",
            }}
          />

          <ArticleContent>
            <VerdictBox>
              <Rating>
                <li>
                  Style:{" "}
                  <span>
                    {`⭐`.repeat(style)}
                    <span style={{ filter: "grayscale(100%" }}>
                      {`⭐`.repeat(5 - Number(style))}
                    </span>
                  </span>
                </li>
                <li>
                  Price:{" "}
                  <span>
                    {`⭐`.repeat(price)}
                    <span style={{ filter: "grayscale(100%" }}>
                      {`⭐`.repeat(5 - Number(price))}
                    </span>
                  </span>
                </li>
                <li>
                  Functionality:{" "}
                  <span>
                    {`⭐`.repeat(functionality)}
                    <span style={{ filter: "grayscale(100%" }}>
                      {`⭐`.repeat(5 - Number(functionality))}
                    </span>
                  </span>
                </li>
                <li>
                  Overall:{" "}
                  <span>
                    {`⭐`.repeat(overallRating)}
                    <span style={{ filter: "grayscale(100%" }}>
                      {`⭐`.repeat(5 - Number(overallRating))}
                    </span>
                  </span>
                </li>
              </Rating>

              <ProsAndCons>
                <div>
                  <h3>Pros:</h3>
                  <ul className="pros">
                    {pros.map((item, index) => {
                      return <li key={index}>{item}</li>
                    })}
                  </ul>
                </div>

                <div>
                  <h3>Cons:</h3>
                  <ul className="cons">
                    {cons.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>

                <Verdict>
                  <h3>Our Verdict:</h3>
                  <p>
                    {showMore
                      ? verdict.childMdx.rawBody
                      : `${verdict.childMdx.rawBody.substring(0, 85)}...`}
                    {` `}
                    {verdict.childMdx.rawBody.length > 80 && (
                      <button onClick={() => setShowMore(!showMore)}>
                        {showMore ? `show less -` : `show more +`}
                      </button>
                    )}
                  </p>
                </Verdict>
              </ProsAndCons>
            </VerdictBox>

            <StyledPostContent>
              {renderRichText(body, options)}

              <EndShareButtons
                site={site}
                article={review}
                hasSubCategory={review.subCategory.slug}
              />

              <Newsletter formWidth="fixed-width" />

              <AuthorBox {...author} />

              <RelatedReviews
                reviews={relatedReviews}
                subCategoryTitle={subCategory.title}
              />
            </StyledPostContent>
          </ArticleContent>
        </ArticleWrapper>
        <Newsletter formWidth="" />
      </ArticleMain>
    </>
  )
}

export default ReviewPage

export const reviewQuery = graphql`
  query ($id: String!, $slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    review: contentfulReview(id: { eq: $id }) {
      id: contentful_id
      title
      slug
      seoDesc: seoDescription
      date: publishDate(formatString: "MMM D, YYYY")
      updatedAt
      summary
      pros
      cons
      price
      functionality
      style
      overallRating
      verdict {
        childMdx {
          rawBody
        }
      }
      category {
        title
        slug
      }
      subCategory {
        title
        slug
      }
      featuredImage {
        description
        gatsbyImageData(
          height: 628
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 70
        )
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              layout: CONSTRAINED
              quality: 70
            )
            description
          }
        }
      }
      author {
        name
        # slug
        bio {
          bio
        }
        photo {
          description
          gatsbyImageData(
            width: 64
            height: 64
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: WEBP
          )
        }
        # socialMedia {
        #   id: contentful_id
        #   title
        #   userId
        #   url
        # }
      }
    }

    relatedReviews: allContentfulReview(
      limit: 4
      sort: { fields: publishDate, order: DESC }
      filter: {
        subCategory: { title: { regex: "/product/i" } }
        id: { ne: $id }
      }
    ) {
      nodes {
        id: contentful_id
        slug
        title
        summary
        publishDate(formatString: "M/D/YY")
        overallRating
        category {
          title
          slug
          subCategories {
            title
            slug
          }
        }
        img: featuredImage {
          description
          gatsbyImageData(
            width: 320
            height: 390
            layout: CONSTRAINED
            # placeholder: TRACED_SVG
            # formats: WEBP
          )
        }
      }
    }

    miniReview: allContentfulReview(
      sort: { fields: publishDate, order: DESC }
      limit: 1
      skip: 1
      filter: { slug: { nin: [$slug, null] } }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }

    brand: allContentfulArticle(
      limit: 1
      skip: 1
      sort: { fields: publishDate, order: DESC }
      filter: {
        slug: { nin: [$slug, null] }
        subCategory: { title: { eq: "Brands of the world" } }
      }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }

    bizExec: allContentfulArticle(
      limit: 1
      skip: 1
      sort: { fields: publishDate, order: DESC }
      filter: {
        slug: { nin: [$slug, null] }
        subCategory: { title: { eq: "Business Executives" } }
      }
    ) {
      nodes {
        title
        slug
        pubDate: publishDate(formatString: "MMM DD, YYYY")
        featuredImage {
          description
          gatsbyImageData(width: 320, height: 160, layout: CONSTRAINED)
        }
        category {
          slug
        }
        subCategory {
          slug
        }
      }
    }
  }
`
