import React from "react"

import { StyledHeadingBar } from "../../styles/headings"
import { RelatedReviewWrapper, RelatedReviewList } from "./ReviewElements"
import ReviewCard from "./ReviewCard"

const RelatedReviews = ({ subCategoryTitle, reviews }) => {
  return (
    <>
      {reviews.nodes.length >= 1 && (
        <RelatedReviewWrapper>
          <StyledHeadingBar style={{ gridColumn: "2 / -2" }}>
            More from <span>{subCategoryTitle ?? subCategoryTitle}</span>
          </StyledHeadingBar>

          <RelatedReviewList>
            {reviews.nodes.map(reviewsNode => {
              return (
                <ReviewCard key={reviewsNode.id} reviewNode={reviewsNode} />
              )
            })}
          </RelatedReviewList>
        </RelatedReviewWrapper>
      )}
    </>
  )
}

export default RelatedReviews
