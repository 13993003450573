import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ReviewCardWrapper, ReviewContentWrapper } from "./ReviewElements"

const ReviewCard = ({ reviewNode }) => {
  const { slug, title, summary, publishDate, overallRating, img, category } =
    reviewNode
  const reviewImage = getImage(img)
  const date = new Date(publishDate)
  const isoDate = date.toISOString()

  return (
    <Link to={`/${category.slug}/${category.subCategories[0].slug}/${slug}/`}>
      <ReviewCardWrapper>
        <GatsbyImage
          as="figure"
          image={reviewImage}
          alt={img.description}
          className="reviewImage"
          // style={{
          // height: "33.75",
          // gridColumn: "1/1",
          // gridRow: "1/1",
          // }}
        />

        <ReviewContentWrapper>
          <div className="reviewHeader muted">
            <time dateTime={isoDate}>{publishDate}</time>
            <div
              aria-label={`Ratings of ${Number(overallRating)} out of 5 stars`}
            >
              {`⭐`.repeat(overallRating)}
              <span style={{ filter: `grayscale(100%)` }}>
                {`⭐`.repeat(5 - Number(overallRating))}
                {/* {`⭐`.repeat(5 - 4)} */}
              </span>
            </div>
          </div>

          <div className="reviewText" style={{ paddingInline: "2rem" }}>
            <h4>{title}</h4>
            <p className="line-clamp">{summary}</p>
          </div>
        </ReviewContentWrapper>
      </ReviewCardWrapper>
    </Link>
  )
}

export default ReviewCard
